import React from "react"

const Affiliate = ({ data }) => {

  if (!data) {
    return (<></>)
  }

  const imageURL = (data => {
    if (!data.Images) { return '' }
    if (!data.Images.Primary) { return '' }

    if (data.Images.Primary.Large) {
      return data.Images.Primary.Large.URL
    } else if (data.Images.Primary.Medium) {
      return data.Images.Primary.Medium.URL
    } else {
      return ''
    }
  })(data)

  return (
    <>
      {imageURL !== '' && (
        <div className="m-4 border border-grey-light rounded
                md:m-4 md:mx-auto
                lg:flex lg:my-4 lg:mx-auto">
          <div className="flex justify-center h-64 relative rounded lg:flex-none lg:w-2/5 lg:px-2 lg:py-4 lg:mx-2">
            <a className="" href={data.DetailPageURL}>
              <img className="object-contain content-center h-56" src={imageURL} />
            </a>
          </div>
          <div className="p-4 flex flex-col justify-between leading-normal">
          <a className="" href={data.DetailPageURL}>
            {data.ItemInfo.Title.DisplayValue}
            </a>
          </div>
        </div>

      )}
    </>
  )
}

export default Affiliate