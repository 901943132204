import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo.js"
import Affiliate from "../components/affiliate"

export default ({ pageContext: { id, asinData }, data }) => {

  const sbLine = (line) => {
    let className = 'line'
    if (line.match(/^([\s|\t|　])*\>/)) {
      line = line.replace(/\>/, '') //最初の > を除去
      className = className + ' bg-gray-200 p-2'
    }
    const m = line.match(/^([\s|\t|　])*/) || []
    const spaces = m[0].match(/[\s|\t|　]/g) || []
    if (spaces.length > 0) {
      return `<div class="${className} mt-2"><span style="display: list-item;margin-left: ${spaces.length * 1}rem;">${replaceTags(line)}</span></div>`
    } else {
      return `<div class="${className} mt-8"><p>${replaceTags(line)}</p></div>`
    }
  }

  const content = (lines, asinData) => {
    const regexp = /=====/
    let content = ''
    for (let line of lines) {
      if (regexp.test(line)) { break }
      content = content + sbLine(line)
    }

    if (asinData){
      content = content.replace(/https:\/\/.*amazon\.com\/images.*jpg/, '')
    }

    return content
  }

  const replaceTags = (line) => {
    let html = line.replace(/#([^\s$]*)/g, '<a href="/$1">$1</a>')
    html = line.replace(/^[\s\t　]*/, '')
    html = html.replace(/\[\[?(https:\/\/gyazo\.com.+?)\]\]?/, '<img src="$1/raw" />')
    html = html.replace(/\[\[?(https:\/\/.+?\.(jpg|jpeg|png))\s*(https:\/\/.+?)?\]\]?/, '<img src="$1" />')
    html = html.replace(/\[(.+?)\]/g, '<a href="/$1">$1</a>')
    return html
  }

  const page = data.pageJson;
  return (
    <>
      <Layout>
        <SEO title={data.pageJson.title} image={data.pageJson.image} />
        <div className="post sm:mx-4 md:mx-12 lg:mx-40 mt-4">
          <div className="mx-4 sm:mx-12">
            <h1 className="text-4xl">{data.pageJson.lines[0]}</h1>
            <Affiliate data={asinData}/>
            <div dangerouslySetInnerHTML={{ __html: content(data.pageJson.lines.slice(1), asinData) }} />
          </div>
          <hr />
          <h2>Links</h2>
          {page.links.map(link => {
            return (
              <Link className="mr-2" to={`/${link}`}>{link}</Link>
            )
          })}
          <h2>Tags</h2>
          {page.tags.map(tag => {
            return (
              <Link className="mr-2" to={`/${tag}`}>{tag}</Link>
            )
          })}
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($id: String){
    pageJson(id: {eq: $id}){
      id
      title
      image
      lines
      asin
      links
      tags
    }
  }
`
